header {
  border-bottom: 1px solid #cccccc;
  @media screen and (max-width: 768px) {
    padding-top: 15px;
  }

  ul {
    list-style: none;
    padding: 40px 0 30px;
    margin: 0;

    @media screen and (max-width: 768px) {
      padding: 15px 0 30px;
    }

    li {
      margin-left: 30px;

      @media screen and (max-width: 768px) {
        margin-left: 0;
        margin-right: 30px;
        display: block;
      }

      a{
        color: #333333;
        font-size: 18px;
      }
    }
  }
}

.wow {
  visibility: hidden;
}

.robot-section {
  padding: 120px 0 60px;
  border-bottom: 2px solid #777777;
  @media screen and (max-width: 768px) {
    padding: 40px 0;
  }

  .robot-icon {
    img {
      width: 100%;
      max-width: 171px;
      margin: 0 auto 20px;
      display: block;
    }
  }

  .robot-ansver {
    font-size: 24px;
    color: #333333;
    padding: 20px 30px;
    background: #f5f5f5;
    border-radius: 15px;
    margin-bottom: 15px;
    line-height: 26px;
    display: none;

    &:first-child {
      border-radius: 0 15px 15px;
    }
  }
}

.buttons-bar {
  padding-top: 15px;

  a {
    border-radius: 5px;
    background: #00b2c7;
    color: #ffffff;
    font-size: 19px;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    padding: 12px 30px;
    display: inline-block;
    margin-top: 15px;
  }
}

.bbc-widget {
  margin-top: 60px;
  img {
    max-width: 100%;
  }
}

.bbc-form {
  border: 1px solid #cccccc;
  border-radius: 15px;
  height: 60px;
  display: flex;
  margin-top: 25px;

  input {
    border: none;
    height: 60px;
    background: none;
    width: calc(50% - 90px);
    color: #999999;
    font-size: 24px;
    padding-left: 25px;

    &:nth-child(2) {
      border-left: 1px solid #ccc;
    }
  }

  a {
    height: 60px;
    width: 180px;
    color: #ffffff;
    font-size: 24px;
    font-weight: 900;
    text-transform: uppercase;
    background: #00b2c7;
    border-radius: 0 15px 15px 0;
    line-height: 60px;
    display: block;
    margin: -1px -1px 0 0;
    text-align: center;
  }
}

.info-section {
  background: #efefef;
  padding-top: 65px;
  margin-top: 55px;

  h2 {
    color: #333333;
    font-size: 30px;
    font-weight: 900;
    padding: 0 0 10px 0;
  }

  h3 {
    color: #333333;
    font-size: 20px;
    font-weight: 900;
    padding: 30px 0 5px;
  }

  span {
    color: #333333;
    font-size: 18px;
    line-height: 22px;
  }

  p {
    color: #333333;
    font-size: 16px;
    line-height: 20px;
  }

  .banner {
    border-top: 1px solid #cccccc;
    margin-top: 60px;
    padding-top: 25px;

    span {
      display: block;
    }

    .bbc-btn {
      border-radius: 5px;
      background: #00b2c7;
      color: #ffffff;
      font-size: 24px;
      font-weight: 900;
      text-align: center;
      text-transform: uppercase;
      padding: 12px 30px;
      display: inline-block;
      margin-top: 30px;
    }

    img {
      display: block;
      margin: 35px auto -50px;
    }
  }
}

footer {
  margin-top: 80px;
  padding-top: 20px;
  padding-bottom: 65px;
  border-top: 1px solid #cccccc;
  p {
    color: #333333;
    font-size: 14px;
    margin: 0;

     a {
      color: #00b2c7;
     }
  }
}