*{
  font-family: 'Lato', sans-serif;
}

*:hover,
*:active,
*:focus {
  outline: none;
}

img {
  max-width: 100%;
}

.container {
  max-width: 1210px;
}

.hidden {
  display: none;
}

a:hover {
  text-decoration: none;
}

.chat ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.message-left .message-time {
  display: block;
  font-size: 12px;
  text-align: left;
  padding-left: 30px;
  padding-top: 4px;
  color: #ccc;
  font-family: Courier;
}

.message-right .message-time {
  display: block;
  font-size: 12px;
  text-align: right;
  padding-right: 20px;
  padding-top: 4px;
  color: #ccc;
  font-family: Courier;
}

.message-left {
  text-align: left;
  margin-bottom: 16px;
}

.message-left .message-text {
  max-width: 80%;
  display: inline-block;
  background: #fff;
  padding: 15px;
  font-size: 14px;
  color: #999;
  border-radius: 30px;
  font-weight: 100;
  line-height: 1.5em;
}

.message-right {
  text-align: right;
  margin-bottom: 16px;
}

.message-right .message-text {
  line-height: 1.5em;
  display: inline-block;
  background: #5ca6fa;
  padding: 15px;
  font-size: 14px;
  color: #fff;
  border-radius: 30px;
  line-height: 1.5em;
  font-weight: 100;
  text-align: left;
}

.chat {
  border-radius: 30px;
  padding: 20px;
  background: #f5f7fa;
}

.chat-container {
  height: 400px;
  overflow-y: scroll;
  padding-right: 16px;
}

.spinme-right {
  display: inline-block;
  padding: 15px 20px;
  font-size: 14px;
  border-radius: 30px;
  line-height: 1.25em;
  font-weight: 100;
  opacity: 0.2;
}

.spinme-left {
  display: inline-block;
  padding: 15px 20px;
  font-size: 14px;
  color: #ccc;
  border-radius: 30px;
  line-height: 1.25em;
  font-weight: 100;
  opacity: 0.2;
}

.spinner {
  margin: 0;
  text-align: center;
}

.spinner > div {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  background: rgba(0,0,0,1);
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}