@import url('https://fonts.googleapis.com/css?family=Lato:300,400,400i,700,700i,900');

@import "../../../node_modules/normalize.css/normalize.css";

//Bootstrap
@import "../../../node_modules/bootstrap/dist/css/bootstrap.css";
@import "../../../node_modules/bootstrap/dist/css/bootstrap-grid.css";
@import "../../../node_modules/bootstrap/dist/css/bootstrap-reboot.css";

////Slick slider
//@import "../../../node_modules/slick-carousel/slick/slick.scss";
//
////noUislider
//@import "../../../node_modules/nouislider/distribute/nouislider.css";
//@import "libs/uiSliderCustom.scss";
//
////niceSelect
//@import "../../../node_modules/jquery-nice-select/css/nice-select.css";
//@import "libs/nice-select";
//
//@import "libs/reveal.scss";

//@import "libs/animate";